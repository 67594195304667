import __request__ from 'routing/request';
interface PaginatedResponse_TokenUsageDTO {
    readonly [_key: string]: Object;
}
/**
 * Get paginated list of tokens
 * @param sort The field to sort the result on
 * @param order The sort direction
 */
export function getPage(sort: 'username' | 'NAME' = 'NAME', page: number = 1, per_page: number = 50, query?: string, order: 'asc' | 'desc' = 'asc'): Promise<PaginatedResponse_TokenUsageDTO> {
    return __request__('GET', '/token_usage/paginated', null, { 'page': page, 'per_page': per_page, 'query': query, 'sort': sort, 'order': order }, {
        'Accept': ['application/json']
    });
}
